(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/assets/javascripts/pnp-start.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/assets/javascripts/pnp-start.js');

'use strict';

const logger = new svs.core.log.Logger('pnp:start-page');
document.addEventListener('DOMContentLoaded', () => {
  const video = document.querySelector('.pnp-start-video');
  const source = video.querySelector('.pnp-start-video-src');
  source.src = source.dataset.src;
  video.load();
  video.play();
  const returnUrl = svs.core.userSession.hasRole('player') ? svs.core.urlMapping.get('pnpHome') : null;
  new svs.accountservices.quick_transaction.QuickTransaction(false, null, false, undefined, returnUrl);
});
function startDeposit() {
  const depositValues = {
    amount: 100,
    skipStepOne: false
  };
  const storageTimeInMinutes = 30;
  svs.components.Storage.browser.set('depositValues', 'quick-deposit', depositValues, storageTimeInMinutes, error => {
    if (error) {
      logger.warn("Failed to set depositValues: ".concat(error));
    }
  });
}
document.querySelector('.js-pnp-start-swish-button') && document.querySelector('.js-pnp-start-swish-button').addEventListener('click', () => {
  const loginUrl = "".concat(svs.core.urlMapping.get('loginHome'), "?").concat(svs.core.urlMapping.get('pnpHome'));
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const depositUrl = "".concat(svs.core.urlMapping.get('quickDeposit'), "?returnUrl=").concat(svs.core.urlMapping.get('pnpHome'));
  if (!isLoggedIn) {
    svs.components.analytics.trackEvent({
      action: 'initiate_onboarding',
      opt_label: '50',
      category: 'Casino'
    });
  }
  startDeposit();
  const target = isLoggedIn ? depositUrl : loginUrl;
  svs.utils.url.navigate(target);
});
svs.components.casino.animateHeader.init();

 })(window);