(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/pnp-casino/views/pnp-start.js') >= 0) return;  svs.modules.push('/modules/pnp-casino/views/pnp-start.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.pnp_casino=_svs.pnp_casino||{};
_svs.pnp_casino.templates=_svs.pnp_casino.templates||{};
svs.pnp_casino.templates.pnp_start = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"transparent":true,"inverted":true,"href":(depth0 != null ? lookupProperty(depth0,"url") : depth0),"size":100,"title":(depth0 != null ? lookupProperty(depth0,"title") : depth0)},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":114}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/pnp-casino/poster_mob.png",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":54},"end":{"line":7,"column":105}}}));
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/pnp-casino/poster.png?v=2",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":113},"end":{"line":7,"column":164}}}));
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <source class=\"pnp-start-video-src\" data-src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/pnp-casino/pnp_start_mobile.mp4",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":52},"end":{"line":9,"column":109}}}))
    + "\" type=\"video/mp4\">\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <source class=\"pnp-start-video-src\" data-src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/pnp-casino/pnp_start_desktop.mp4",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":110}}}))
    + "\" type=\"video/mp4\">\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Sätt in via "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"bank",{"name":"ui","hash":{"name":"swish","size":24},"data":data,"loc":{"start":{"line":17,"column":123},"end":{"line":17,"column":157}}}));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"size":300,"block":true,"href":(lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"loginHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":19,"column":88},"end":{"line":19,"column":112}}}),"class":"pnp-startpage-button","inverted":true,"transparent":true},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":149}}})) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "Logga in";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<section class=\"pnp-start\">\n  <nav class=\"pnp-start-buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"links") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":13}}})) != null ? stack1 : "")
    + "  </nav>\n  <video class=\"pnp-start-video\" poster=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":177}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" preload=\"none\" muted loop playsinline>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":17}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </video>\n  <div class=\"pnp-start-center\">\n    <div class=\"pnp-start-center-content\">\n      <h1 class=\"pnp-start-text f-bold\">"
    + alias5(((helper = (helper = lookupProperty(helpers,"startPageText") || (depth0 != null ? lookupProperty(depth0,"startPageText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startPageText","hash":{},"data":data,"loc":{"start":{"line":16,"column":40},"end":{"line":16,"column":57}}}) : helper)))
    + "</h1>\n      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"block":true,"size":300,"class":"pnp-startpage-button js-pnp-start-swish-button","inverted":true},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":164}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"loggedIn") : stack1),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":6},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"visible-am visible-sm visible-xs\"> \n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-accountservices-quick_transaction-quick_deposit"),depth0,{"name":"components-accountservices-quick_transaction-quick_deposit","hash":{"hide":true},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</section>\n"
    + alias5((lookupProperty(helpers,"widgetArea")||(depth0 && lookupProperty(depth0,"widgetArea"))||alias2).call(alias1,{"name":"widgetArea","hash":{"name":"startContent"},"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":27,"column":34}}}))
    + "\n";
},"usePartial":true,"useData":true});
Handlebars.partials['svs-pnp_casino-pnp_start'] = svs.pnp_casino.templates.pnp_start;
})(svs, Handlebars);


 })(window);